<template>
  <BaseReservation>
    <template v-slot:default>
      <div class="mt-5 mb-3 flex-header">
        <v-btn outlined small class="mr-5 custom-btn" color="#000000" @click="getDate()">
          {{ $t("common.toDay") }}
        </v-btn>

        <!-- Date picker -->
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <div>
              <v-icon style="color: #000000">mdi-calendar-month</v-icon>
              <v-btn :value="changeDate" v-on="on" small class="btn--date">{{
                formatDate(changeDate)
              }}</v-btn>
            </div>
          </template>
          <v-date-picker
            @change="changefromDate($event)"
            :value="changeDate"
            :first-day-of-week="0"
            :locale="$i18n.locale"
            scrollable
            @input="menuDate = false"
            class="v-date-picker-custom"
          ></v-date-picker>
        </v-menu>
        <!--  -->
        <span
          class="text--gray ml-1 mr-5"
          style="font-size: 13px; color: #8e8e8e !important"
        >
          {{ $t("reservation.showSubsequentReservations") }}
        </span>

        <span class="custom-filed">{{ $t("common.facility") }}</span>
        <v-select
          @change="changeFacility($event)"
          v-model="facilitySelected"
          :items="facilityList"
          item-text="name"
          item-value="id"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-1 facility-list"
        ></v-select>

        <span class="ml-5 custom-filed">{{
          $t("reservation.typeOfMailingHope")
        }}</span>
        <v-select
          @change="changeMailRequest($event)"
          v-model="mailRequest"
          :items="BookingListMailRequestType"
          item-text="value"
          item-value="key"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-1"
        ></v-select>
      </div>

      <div class="d-flex align-center my-5">
        <span style="color: #000000"> {{ $t("common.checked") }} </span>
        <v-btn class="btn--primary ml-3" @click="generatePdf" :disabled="certificateSelected.length === 0 || !checkUser">
          {{ $t("buttons.memberAddressLabelPrinting") }}
        </v-btn>
        <v-btn class="btn--primary ml-3" @click="showCertificateFiles" :disabled="certificateSelected.length === 0 || !checkUser">
          {{ $t("buttons.printThecertificateOfUse") }}
        </v-btn>
      </div>

      <v-card class="pb-5" elevation="0">
        <v-data-table
          :server-items-length="total"
          :locale="$i18n.locale"
          @current-items="getListSearch"
          :item-class="itemRowBackground"
          :search="searchText"
          :headers="headers"
          :items="bookingList"
          class="elevation-1 table-custom table-custom-select --multi-colors"
          hide-default-footer
          @pagination="getNumOfPage"
          v-model="certificateSelected"
          item-key="id"
          :options.sync="options"
          :show-select="checkUser"
          :items-per-page="100"
          multi-sort
          dense
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              :disabled="!checkUser"
              outlined
              small
              color="var(--text__primary)"
              class="bg--white"
              :to="
                '/reservation/contract-detail?id=' +
                item.id +
                '&tab=basic-information'
              "
              target="_blank"
            >
              {{ $t("buttons.edit") }}
            </v-btn>
          </template>

          <template v-slot:[`item.contract`]="{ item }">
            <span v-if="item.bookingType.code">{{
              item.bookingType.code
            }}</span>
          </template>

          <template v-slot:[`item.mailBookingCertificate`]="{ item }">
            <span v-if="item.mailBookingCertificate">{{ "郵送希望" }}</span>
          </template>

          <template v-slot:[`item.facilityInformation`]="{ item }">
            <span v-if="item.mailFacilityGuide">{{ "郵送希望" }}</span>
          </template>

          <template v-slot:[`item.pointDocument`]="{ item }">
            <span v-if="item.mailPointCertificate">{{ "郵送希望" }}</span>
          </template>

          <template v-slot:[`item.leaflets`]="{ item }">
            <span v-if="item.mailAds">{{ "郵送希望" }}</span>
          </template>

          <template v-slot:[`item.facility`]="{ item }">
            <span v-if="item.room.roomType.facility.name">{{
              item.room.roomType.facility.name
            }}</span>
          </template>

          <template v-slot:[`item.roomType`]="{ item }">
            <span v-if="item.room.roomType.name">{{
              item.room.roomType.name
            }}</span>
          </template>

          <template v-slot:[`item.room`]="{ item }">
            <span v-if="item.room.name">{{ item.room.name }}</span>
          </template>

          <template v-slot:[`item.representative`]="{ item }">
            <span v-if="item.representative">{{
              item.representative.name
            }}</span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | dateTimeSting }}
          </template>
          <template v-slot:[`item.lastUpdatedAt`]="{ item }">
            {{ item.computedForList.lastUpdatedAt | dateTimeSting }}
          </template>
          <template v-slot:[`item.checkInDate`]="{ item }">
            <span v-if="item.checkInDate">{{
              item.checkInDate.replace(/-/g, "/")
            }}</span>
          </template>

          <template v-slot:[`item.checkOutDate`]="{ item }">
            <span v-if="item.checkOutDate">{{
              item.checkOutDate.replace(/-/g, "/")
            }}</span>
          </template>

          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-data-footer
              :pagination="pagination"
              :options="options"
              @update:options="updateOptions"
              :items-per-page-text="
                numLength +
                $t('common.cases') +
                itemStart +
                '~' +
                itemStop +
                $t('common.showing')
              "
              :itemsPerPageOptions="[100, 200, 500]"
            />
          </template>
        </v-data-table>
      </v-card>
      <div v-if="printList.length > 0" id="client-pdf-table">
        <div class="d-flex flex-wrap">
          <template v-if="orderSelected > 1">
            <div v-for="el in (orderSelected - 1)" :key="el + 'p'" class="client-item"></div>
          </template>
          <ClientItem v-for="item in printList" :key="item.id" :data="item.contract"/>
        </div>
      </div>
      <v-dialog v-model="isOpenSelectPositionPrint" max-width="300" content-class="pa-5 pt-3 bg--white">
        <h1 class="text--heading mb-4">ラベル印刷</h1>
        <v-select
          :items="orderList"
          v-model="orderSelected"
          label="印刷開始位置を選択"
        ></v-select>
        <div class="d-flex justify-end">
          <v-btn
            color="#C92D2D"
            class="white--text mr-3"
            @click="isOpenSelectPositionPrint = false"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="#1873D9"
            class="white--text"
            @click="downloadClientLabelPdf"
          >
            PDF出力
          </v-btn>
        </div>
      </v-dialog>
    </template>
  </BaseReservation>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { i18n } from '@/plugins/i18n'
import BaseReservation from '@/components/Reservation/BaseReservation'
import ClientItem from '@/components/ClientItem'
import { listContractBackground } from '@/constants/contractColors'
import { LIST_MAIL_REQUEST } from '@/constants/mailRequestType'
import { formatDate } from '@/constants/functions'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { BOOKING_LIST } from '@/api/graphql/guestRoomDailyReport/send-usage-certificate'
import { nextOrPrevDay } from '@/utils/handleDate'
import { mapMutations } from 'vuex'
import gql from 'graphql-tag'

export default {
  name: 'ReservationSendUsageCertificate',
  data () {
    return {
      orderBy: null,
      filter: {},
      skip: 0,
      take: 100,
      total: null,
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      bookingList: [],
      headers: [
        {
          text: '',
          value: 'action',
          sortable: false
        },
        {
          text: i18n.t('reservation.reservationDate'),
          value: 'createdAt',
          sortable: true
        },
        {
          text: '最終変更日時',
          value: 'lastUpdatedAt',
          sortable: true
        },
        {
          text: i18n.t('common.contract'),
          value: 'contract',
          sortable: true
        },
        {
          text: i18n.t('reservation.userCertificate'),
          value: 'mailBookingCertificate',
          sortable: true
        },
        {
          text: i18n.t('reservation.facilityInformation'),
          value: 'facilityInformation',
          sortable: false
        },
        {
          text: i18n.t('reservation.pointDocument'),
          value: 'pointDocument',
          sortable: false
        },
        {
          text: i18n.t('reservation.leaflets'),
          value: 'leaflets',
          sortable: false
        },
        {
          text: i18n.t('reservation.reservationID'),
          value: 'id',
          sortable: false
        },
        {
          text: i18n.t('common.facility'),
          value: 'facility',
          sortable: false
        },
        {
          text: i18n.t('common.roomType'),
          value: 'roomType',
          sortable: false
        },
        {
          text: i18n.t('common.room'),
          value: 'room',
          sortable: false
        },
        {
          text: i18n.t('common.checkIn'),
          value: 'checkInDate',
          sortable: false
        },
        {
          text: i18n.t('common.checkOut'),
          value: 'checkOutDate',
          sortable: false
        },
        {
          text: i18n.t('common.representative'),
          value: 'representative',
          sortable: false
        }
      ],
      options: {
      },
      actionPaginate: false,
      timeoutSearch: null,
      facilityList: [],
      certificateList: [],
      certificateSelected: [],
      facilitySelected: null,
      typeOfMailingSelected: '全て',
      numLength: 0,
      itemStart: 0,
      itemStop: 0,
      searchText: '',
      listSearch: [],
      menuDate: false,
      date: new Date().toISOString().substr(0, 10),
      changeDate: new Date().toISOString().substr(0, 10),
      cancelled: false,
      mailRequest: 'Any',
      getNowDate: false,
      BookingListMailRequestType: LIST_MAIL_REQUEST,
      printList: [],
      isOpenSelectPositionPrint: false,
      orderSelected: 1,
      orderList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  },

  watch: {
    options: {
      handler () {
        this.fetchBookingListTmp()
        this.actionPaginate = !this.actionPaginate
      },
      deep: true
    },

    actionPaginate () {
      setTimeout(() => {
        this.getBookingList(this.filter, this.orderBy)
      }, 200)
    }
  },

  async mounted () {
    this.filter = {
      checkInFromDate: this.changeDate,
      cancelled: false,
      mailRequest: this.mailRequest
    }
    this.getFacility()
  },
  methods: {
    ...mapMutations(['setLoadingOverlayHide', 'setLoadingOverlayShow']),
    nextOrPrevDay,
    formatDate,

    fetchBookingListTmp () {
      const arrSortBook = ['contract', 'mailBookingCertificate', 'createdAt']
      const computedForListSorts = ['lastUpdatedAt']
      const arrOrder = []
      if (this.options.sortBy.length > 0) {
        this.options.sortBy.map((orderItem, ind) => {
          this.options.sortDesc.map((directionItem, index) => {
            if (ind === index) {
              let orderItemTmp = null
              if (arrSortBook.includes(orderItem)) {
                orderItemTmp = {
                  fieldName: `booking.${orderItem}`,
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              } else if (computedForListSorts.includes(orderItem)) {
                orderItemTmp = {
                  fieldName: `computedForList.${orderItem}`,
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              arrOrder.push(orderItemTmp)
            }
          })
        })
      }
      this.orderBy = arrOrder
      this.getBookingList(this.filter, this.orderBy)
    },

    generatePdf () {
      this.isOpenSelectPositionPrint = true
    },
    showCertificateFiles () {
      this.certificateSelected.length > 0 && this.certificateSelected.map(item => item.bookingCertificateUrl && window.open(item.bookingCertificateUrl, '_blank'))
    },
    getNumOfPage (pagination) {
      this.numLength = pagination.itemsLength
      this.itemStart = pagination.pageStart + 1
      this.itemStop = pagination.pageStop
      this.take = pagination.itemsPerPage
      this.skip = (pagination.page - 1) * pagination.itemsPerPage
    },

    getDate () {
      this.getNowDate = true
      this.changeDate = this.date
      this.filter = {
        cancelled: this.cancelled,
        checkInFromDate: this.date
      }
      if (this.facilitySelected) {
        this.filter.facilityId = this.facilitySelected
      }
      if (this.mailRequest) {
        this.filter.mailRequest = this.mailRequest
      }
      this.getBookingList(this.filter, this.orderBy)
    },

    changefromDate (event) {
      this.getNowDate = false
      this.changeDate = event
      this.filter = {
        cancelled: this.cancelled
      }
      this.filter.checkInFromDate = event
      if (this.facilitySelected) {
        this.filter.facilityId = this.facilitySelected
      }
      if (this.getNowDate) {
        this.filter.checkInFromDate = this.date
      }
      if (this.mailRequest) {
        this.filter.mailRequest = this.mailRequest
      }
      this.getBookingList(this.filter, this.orderBy)
    },

    changeFacility (event) {
      this.filter = {
        cancelled: this.cancelled
      }
      if (event) {
        this.filter.facilityId = event
      } else {
        delete this.filter.facilityId
      }

      if (this.getNowDate) {
        this.filter.checkInFromDate = this.date
      } else {
        this.filter.checkInFromDate = this.changeDate
      }
      if (this.mailRequest) {
        this.filter.mailRequest = this.mailRequest
      }
      this.getBookingList(this.filter, this.orderBy)
    },

    changeMailRequest (event) {
      this.filter = {
        cancelled: this.cancelled
      }
      if (event !== '') {
        this.cancelled = false
        this.filter.mailRequest = event
      } else {
        delete this.filter.mailRequest
        this.cancelled = true
      }
      // if (this.mailRequest) {
      //   filter.mailRequest = this.mailRequest
      // }

      if (this.facilitySelected) {
        this.filter.facilityId = this.facilitySelected
      }

      if (this.getNowDate) {
        this.filter.checkInFromDate = this.date
      } else {
        this.filter.checkInFromDate = this.changeDate
      }
      this.getBookingList(this.filter, this.orderBy)
    },

    async getFacility () {
      await this.$apollo
        .query({
          query: gql`${FACILITY_LIST}`
        })
        .then((data) => {
          this.facilityList = data.data.facilityList.slice()
          const item = {
            id: null,
            name: '全て'
          }
          this.facilityList.unshift(item)
        })
        .catch((error) => {
          console.error(error)
        })
    },

    async getBookingList (filter, orderBy) {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`${BOOKING_LIST}`,
          variables: {
            filter: filter,
            orderBy: orderBy,
            pagination: {
              skip: this.skip,
              take: this.take
            }
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.bookingList = data.data.bookingList.items
          this.total = data.data.bookingList.total
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },

    // Add color for each contract (data in contractColors file)
    itemRowBackground (item) {
      if (item.blockRoom) {
        return 'bg-contract--block'
      }
      let contract = item.bookingType.code.trim()
      if (contract.includes('SP')) {
        contract = 'SP'
      }
      return listContractBackground[contract] + '-light'
    },

    getListSearch (e) {
      this.listSearch = e
    },

    async downloadClientLabelPdf () {
      const clientIds = [
        ...new Set(this.certificateSelected.map(booking => booking.clientId ?? booking.contract?.clientId))
      ].filter(v => !!v)
      if (clientIds.length === 0) {
        this.$store.commit('setAlertError', '顧客に紐づく予約が選択されていません')
        return
      }
      this.$store.commit('setLoadingOverlayShow')
      try {
        const result = await this.$apollo.query({
          query: gql`query (
        $clientIds: [Int!]!
        $startIndex: Int!
      ) {
        customerLabelPdf(
          clientIds: $clientIds
          startIndex: $startIndex
        ) {
          files {
            fileName
            downloadUrl
          }
        }
      }`,
          variables: {
            clientIds,
            startIndex: this.orderSelected
          }
        })

        window.open(result.data.customerLabelPdf.files[0].downloadUrl, '_blank').focus()
        this.isOpenSelectPositionPrint = false
      } finally {
        this.$store.commit('setLoadingOverlayHide')
      }
    }
  },
  components: {
    BaseReservation,
    ClientItem
  }
}
</script>

<style scoped lang="scss">
.client-item {
  height: 187px;
  width: 50%;
}
.p-relative {
  position: relative;
}
#client-pdf-table {
  position: absolute;
  left: -150%;
  display: none;
  width: 794px;
  page-break-after: always;
  min-height: 1122px;
  .d-flex.flex-wrap {
    width: 100%
  }
}
::v-deep {
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr{
    background: #eee ;
  }
  &.v-data-table {
    .theme--light.v-icon {
      color: var(--bg__primary);
    }
    th:first-child,
    tbody > tr > td:first-child {
      padding-right: 0;
    }
  }
  &.search-results {
    position: absolute;
    top: calc(100% - 10px);
    right: 173px;
    width: 400px;
    max-height: 552px;
    overflow-x: auto;
    z-index: 1;
    &__item:not(:last-child) {
      border-bottom: solid 1px var(--border__white);
    }
  }
  &.v-data-footer {
    border: none !important;
    @media (min-width: 1200px) {
      position: absolute;
      right: 16px;
      bottom: 100%;
    }
  }
  .custom-btn {
    font-size: 10px;
    border: 1px solid #888888 !important;
  }
  .custom-filed {
    color: #000000 !important;
    font-size: 12px !important;
  }
  .v-select__selection--comma {
    color: #000000 !important;
  }
  .v-select-custom svg {
    color: #000000 !important;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    color: #757575 !important;
    font-size: 14px !important;
  }
  .v-data-table {
      tbody tr:not(.bg-contract--block) td{
        color: #424242 !important;
        font-size: 16px !important;
      }
    }
    .v-data-footer {
      color: #000000 !important;
      font-size: 13px;
    }
    .v-data-footer svg {
      color: #333333 !important
    }
    .v-data-footer .v-select__selection--comma {
      color: #000000 !important;
      font-size: 13px;
    }
}
</style>
<style lang="scss">
.table-custom-select {
  .v-data-table__checkbox {
    display: flex;
  }
  .v-data-footer__pagination {
    display: none;
  }
}
</style>
