import gql from 'graphql-tag'

export const BOOKING_LIST = gql`
  query(
    $orderBy: [OrderByInput!]
    $pagination: PaginationInput
    $filter: BookingListFilterInput
  ) {
    bookingList(orderBy: $orderBy, pagination: $pagination, filter: $filter) {
      total
      hasMore
      items {
        id
        bookingType {
          name
          id
          code
        }
        mailBookingCertificate
        mailFacilityGuide
        mailPointCertificate
        bookingCertificateUrl
        mailAds
        bookingGroupId
        room {
          name
          roomType {
            name
            facility {
              name
            }
          }
        }
        bookingPlan {
          title
        }
        checkInDate
        checkOutDate
        representative {
          name
        }
        createdAt
        computedForList {
          lastUpdatedAt
        }
        clientId
        contract {
          id
          clientId
        }
      }
    }
  }
`
