<template>
  <div class="px-3 rounded d-flex flex-column">
    <h5 class="mb-2">
      {{ data.client.mainContact && data.client.mainContact.postalCode }}
    </h5>
    <div v-if="data.client.mainContact">
      {{ data.client.mainContact.prefecture }}
      {{ data.client.mainContact.locality }}
      {{ data.client.mainContact.address1 }}
      {{ data.client.mainContact.address2 }}
    </div>
    <div class="ml-6 mt-6">
      <template v-if="!data.client.isCompany">
        <template
          v-if="data.client.mainContact && data.client.mainContact.labelName"
        >
          {{ data.client.mainContact.labelName }}
        </template>
        <template v-else>
          {{ data.client.lastName }}
          {{ data.client.firstName }}
        </template>
        様
      </template>
      <template v-else>
        <h2 class="mb-2">{{ data.client.companyName }}</h2>
        <template
          v-if="data.client.mainContact && data.client.mainContact.labelName"
        >
          {{ data.client.mainContact.labelName }}
        </template>
        <template v-else>
          {{ data.client.companyPicDepartment }}
          {{ data.client.companyPicTitle }}
          <h3 class="mt-2">{{ data.client.companyPicName }}</h3>
        </template>
      </template>
    </div>
    <div class="text-right mt-auto">
      {{ data.client.id }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientItem',
  props: {
    data: Object
  }
}
</script>

<style lang="scss" scoped>
div.rounded {
  height: 187px;
  width: 50%;
}
* {
  font-size: 15px !important;
  font-weight: 500 !important;
}
</style>
