<template>
  <div>
    <div class="header-main">
      <h1 class="my-4 page-title-main">
        {{ $t('routeTitles.reservation.reservation') }}
      </h1>
      <search-booking />
    </div>
    <template>
      <div class="px-main">
        <v-tabs v-model="tab" class="t-tab-custom">
          <v-tab v-for="(item, i) in items" :key="i" :to="item.to">{{
            item.title
          }}</v-tab>
        </v-tabs>
      <slot name="default" />
      </div>
    </template>
  </div>
</template>

<script>
import { checkPermissionTab } from '@/utils/permissions'
import SearchBooking from '@/components/SearchBooking.vue'

export default {
  name: 'BaseReservation',
  data () {
    return {
      tab: null,
      items: [],
      itemsTmp: [
        {
          title: this.$t('routeTitles.reservation.reservationList'),
          to: '/reservation/reservation-list',
          pathRoot: '/reservation/reservation-list',
          order: 1
        },
        {
          title: this.$t('routeTitles.reservation.sendUsageCertificate'),
          to: '/reservation/send-usage-certificate',
          pathRoot: '/reservation/send-usage-certificate',
          order: 2
        },
        {
          title: this.$t('routeTitles.reservation.cancellationApplied'),
          to: '/reservation/cancellation-applied',
          pathRoot: '/reservation/cancellation-applied',
          order: 3
        }
      ]
    }
  },
  components: { SearchBooking },
  mounted () {
    this.checkPerTab()
  },
  methods: {
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-main {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep {
    .v-tab--active {
      color: #1873D9 !important;
      font-size: 16px !important;
    }
    .v-tab:not(.v-tab--active) {
      color: #aaaaaa !important;
      font-size: 16px !important
    }
  }
</style>
